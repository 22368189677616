<template>
    <div>
        <div class="page-top-box">
            <div class="page-title-box">
                <div class="go-back">
                    <el-button type="text" icon="el-icon-back" @click="goBack">返回</el-button>|
                </div>
                {{ $route.meta.title }}
            </div>
        </div>
        <div class="page-content-box info-box">
            <div class="icon"><img src="@/assets/img/common/success.png"/></div>
            <div class="msg">演出已发布成功！</div>
            <div class="btn"><el-button type="primary" size="small" @click="showList">查看演出列表</el-button></div>
        </div>
        <el-row :gutter="12" type="flex">
            <el-col :span="12">
                <dl class="page-content-box">
                    <dt>接下来会发生什么</dt>
                    <dd>1、请耐心等待秀动官方进行审核，如有任何疑问，请联系秀动官方客服</dd>
                    <dd>2、演出审核结果会发送通知短信至演出联系人手机，<span class="color-danger">请勿屏蔽</span></dd>
                </dl>
            </el-col>
            <el-col :span="12">
                <dl class="page-content-box">
                    <dt>创建巡演</dt>
                    <dd>如需创建巡演，可在<el-button type="text" @click="showList" size="medium">【演出列表】</el-button>，基于当前演出，进行快速复制以形成巡演</dd>
                </dl>
            </el-col>
        </el-row>
        <el-row v-loading="dataLoading">
            <el-col>
                <dl class="page-content-box">
                    <dt>演出二维码</dt>
                    <dd v-if="activity" class="activity-info clearfix">
                        <div class="fl">
                            <vue-qr :logoSrc="logo" :text="activity.wapQrCodeUrl" :size="800" :logoMargin="8" logoBackgroundColor="#ffffff" :callback="getImage" :margin="0"></vue-qr>
                        </div>
                        <div class="info">
                            <div class="color-danger">注意：演出审核通过前，请勿泄露演出二维码和演出链接。</div>
                            <div class="links links1">
                                <span class="color-regular">PC端演出链接：</span>
                                <el-link type="primary" :href="activity.pcQrCodeUrl" target="_blank">{{activity.pcQrCodeUrl}}</el-link>
                            </div>
                            <div class="links">
                                <span class="color-regular">WAP站演出链接：</span>
                                <el-link type="primary" :href="activity.wapQrCodeUrl" target="_blank">{{activity.wapQrCodeUrl}}</el-link>
                            </div>
                            <el-button size="small" @click="downloadCode">下载二维码</el-button>
                        </div>
                    </dd>
                </dl>
            </el-col>
        </el-row>
    </div>
</template>

<script>
import logo from '@/assets/img/logo2x.png';
import VueQr from 'vue-qr'

export default {
    props: {
        id: {
            type: [Number, String],
            default: null,
        },
    },
    components:{VueQr},
    data(){
        return {
            redirect: this.$route.query.redirect || `/manage/activity/edit/step3/${this.id}`,
            logo:logo,
            codeImage:'',
            activity: null,
            dataLoading: false
        }
    },
    created(){
        this.getDataDetail();
    },
    methods:{
        //获取详情
        getDataDetail() {
            this.dataLoading = true;
            this.$request({
                url: "/activity/publishSuccess",
                params: { activityId: parseInt(this.id) },
                method: "post",
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1) {
                        this.activity = result;
                    } else {
                        self.$message.error(msg || "获取演出详情失败,请重试");
                    }
                })
                .catch(() => {
                    this.$message.error("获取演出详情失败,请重试");
                })
                .finally(() => {
                    this.dataLoading = false;
                });
        },
        //返回
        goBack() {
            this.$router.push({
                path: this.redirect,
            });
        },
        showList(){
            this.$router.push({name:'activityList'})
        },
        showMsg(){
            this.$router.push({name:'systemMsg'})
        },
        getImage(url){
            this.codeImage = url;
        },
        downloadCode(){
            const _data = this.$utils.base64ToBlob(this.codeImage);
            this.$utils.exportFile(_data,`${this.activity.title}的二维码.png`);
        },
    }
}
</script>

<style lang="scss" scoped>
.info-box{
    padding: 48px;
    text-align: center;
    .icon{
        img{
            width: 88px;
        }
    }
    .msg{
        margin-top: 28px;
        font-size: 24px;
        font-weight: 500;
    }
    .btn{
        margin-top: 24px;
    }
}
.el-row{
    margin-top: 12px;
    .el-col{
        .page-content-box{
            height: 100%;
            padding: 36px;
            dt{
                font-size: 16px;
                font-weight: 500;
                margin-bottom: 20px;
            }
            dd{
                color: $--color-text-regular;
                font-size: 14px;
                line-height: 24px;
            }
        }
    }
}
.activity-info{
    .fl{
        margin-right: 25px;
        img{
            width: 150px;
            height: 150px;
        }
    }
    .info{
        overflow: hidden;
        .links1{
            margin-top: 38px;
        }
        .el-button{
            margin-top: 6px;
        }
    }
}
</style>